import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import ProjectGrid from "../../components/ProjectGrid";
import NavigateBlock from "../../components/NavigateBlock";
import ogIMage from "../../images/og.png";

const CaseStudiesPage = ({ location }) => {
    const featuredCaseStudies = useStaticQuery(graphql`
        {
            allContentfulCasestudies(sort: { order: DESC, fields: createdAt }) {
                nodes {
                    id
                    name
                    logo {
                        gatsbyImageData(placeholder: BLURRED, quality: 100)
                        title
                    }
                    services {
                        id
                        title
                    }
                    description {
                        description
                    }
                    doneBy {
                        name
                    }
                    backgroundType
                    backgroundMedia {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                        title
                        file {
                            contentType
                            url
                        }
                    }
                    colorTheme
                    thumbnailTheme
                    slug
                    type
                }
            }
        }
    `);
    const normalCaseStudies = featuredCaseStudies.allContentfulCasestudies.nodes?.filter(
        (caseStudy) => caseStudy.type && caseStudy.type !== "AI"
    );
    return (
        <Layout>
            <Seo
                title="Case Studies - Sedin Technologies"
                description={
                    "Sedin highlights business, consulting, and technology case studies, reflecting how we help our clients overcome challenges, across all industries."
                }
                url={location.href}
                image={`https://sedintechnologies.com${ogIMage}/`}
            />
            <section className="casestudies-hero">
                <div className="container container--first">
                    <div className="text">
                        <div className="section-tag gap-2x">Case Studies</div>
                        <h1 className="text-h2 text-fw-medium text-clr-primary">
                            We build bridges between ideas and execution.
                        </h1>
                    </div>
                </div>
            </section>
            <section className="casestudies-projects">
                <div className="container container--py">
                    <ProjectGrid CaseStudiesData={normalCaseStudies} />
                </div>
            </section>
            <section className="casestudies-contact">
                <div className="container container--last">
                    <NavigateBlock />
                </div>
            </section>
        </Layout>
    );
};

export default CaseStudiesPage;
